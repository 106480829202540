import Vue from 'vue'
import App from './App.vue'

import router from '@/setup/router'
import store from '@/setup/store'

import * as CONFIG from '@/setup/init.js'
import language from '@/assets/language/lang.json'

//cookie
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// Bootstrap
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap/scss/bootstrap.scss'
//bootstrap scss is removed to sass/vendors/bootstrap.scss
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Moment.js
const moment = require('moment')
require('moment/locale/hu')

Vue.use(require('vue-moment'), {moment})

//axios
import axios from 'axios'
Vue.prototype.$axios = axios

// Main scss
import '@/sass/main.scss'

//MIXINS
import globalMI from './mixins/globalMI.js';
Vue.mixin(globalMI)

//LANGUAGE MIXIN
Vue.mixin({
  data() {
    return {
      lang: language
    }
  },
  methods: {
    tr(text){
      return this.lang[text][this.currentLangCode]
    },
    setLang(id, code){
      document.querySelector("#app").classList.add("lang-transition");
      setTimeout(() => {
        this.$store.commit("updateLang", id)
        this.$route.params.lang = code
        // this.$router.push('/' + code + '/')
        this.$router.push({name: 'home'})
        this.$router.go(this.$router.currentRoute)
      }, 750)
    },
    changeDate(locale) {
      let dateLang
      locale == "hu" ? dateLang = "hu" : dateLang = "en-gb" 
      moment.locale(dateLang)
    }
  },
  computed: {
      currentLangId() { 
        return this.$store.state.language_id
      },
      currentLangCode() {
        return CONFIG.languages[store.state.language_id].code
      }
  },
  created() {
    this.changeDate(CONFIG.languages[store.state.language_id].code)
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
