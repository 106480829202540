<template>
    <div class="page-title">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12" md="6" order="2" order-md="1" class="page-title__box">
                    <div class="page-title__content">
                        <h1> {{ title }} </h1>
                        <p> {{  description }}</p>
                        <div
                            v-if="button!=='null'" 
                            class="page-title__content--button">
                            <SimpleBtn
                                type="light"
                                :link="button"       
                                :dlCategory="'introduction'"
                                :dlAction="'learn_more'"
                                :dlLabel="''"
                            >                              
                                    {{tr("more-details")}}
                            </SimpleBtn>  
                        </div>
                    </div>
                </b-col>        
                <b-col cols="12" md="6" order="1" order-md="2" class="page-title__box">
                    <div class="page-title__image" v-if="carousel">
                        <b-carousel
                            id="carousel-title"
                            fade
                            :indicators="carousel.length == 1 ? false : true"
                        >
                            <b-carousel-slide v-for="(image,idx) in carousel" :key="'carousel' + idx"
                                :img-src="imgUrlBcg(image.image)"
                            >
                            </b-carousel-slide>
                        </b-carousel>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>  
</template>

<script>
import SimpleBtn from '@/components/decors/SimpleBtn.vue'
export default {
    components: {
        SimpleBtn
    },
    props: [
        'title', 
        'description',
        'carousel',
        'button'
    ],
    data(){
        return{
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME
        }
    },
    methods: {
        imgUrl: function (data) {
            return this.src_url + data.cache.w540h540 
        },
        imgUrlBcg: function (data) {
            return this.src_url + data.cache.w740h740
        }
    }
}
</script>