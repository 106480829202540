<template>
    <div class="lang-switcher"
        v-b-toggle.collapse-lang
        @click="isOpen = !isOpen"
    >
        <div class="lang-switcher__current">
            <p 
                :class="isOpen ? 'lang-switcher__current--open' : ''"
            >
                {{languages[currentLangId].name}}
            </p>
        </div>
        <b-collapse
            id="collapse-lang"
            class="lang-switcher__more"    
        >
            <div
                class="lang-switcher__more--items" 
                v-for="(lang, idx) in languages" :key="'lang' + idx"
            >
                <p 
                    v-if="lang.id !== languages[currentLangId].id"
                    @click="setLang(lang.id, lang.code), setNavItemGTM(lang.code)"
                >
                    {{lang.name}}
                </p>
            </div>
        </b-collapse>
    </div>
</template>

<script>
export default {
    name: 'LangSwitcher',
    props: [
        'languages'
    ],
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        setNavItemGTM(item){    
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'language',
                'action': item + '_click'
        })
    }
    }
}
</script>