import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

import store from '@/setup/store.js'
import * as CONFIG from '@/setup/init.js'

//collect languages
const langsArray = []
Object.keys(CONFIG.languages).forEach(function(key) {
  langsArray.push(CONFIG.languages[key].code)
})

Vue.use(VueRouter)

const routes = [
  { 
    path: "/:lang",              
    component: {
      render: (h) => h('router-view')                   
    },
    beforeEnter(to, from, next) {    
      const lang = to.params.lang
      // console.log(lang) 
      if (!langsArray.includes(lang)) return next(CONFIG.languages[store.state.language_id].code)
      return next()                            
    },
    children: [
      {
        path: '',
        name: 'home',
        component: Home
      },    
      {
        path: 'karrier',
        name: 'career_hu',
        component: () => import('@/views/Career.vue'),
      },
      {
        path: 'career',
        name: 'career_en',
        component: () => import('@/views/Career.vue'),
      },
      {
        path: 'aktualitasok',
        name: 'news',
        component: () => import('@/views/News.vue'),
      },
      {
        path: 'news',
        name: 'news_en',
        component: () => import('@/views/News.vue'),
      },
      {
        path: 'szechenyi',
        name: 'szechenyi',
        component: () => import('@/views/Szechenyi.vue'),
      },
      {
        path: 'szechenyi',
        name: 'szechenyi_en',
        component: () => import('@/views/Szechenyi.vue'),
      },
      {
        path: ':slug', 
        name: 'Info',
        components: {
          default: () => import('@/views/Info.vue'),   
        },
      },
      {
        path: ':slug/:sub_slug', 
        name: 'SubInfo',
        components: {
          default: () => import('@/views/Info.vue'),   
        },
      },
      {
        path: ':slug/:sub_slug/:sub_sub_slug', 
        name: 'SubSubInfo',
        components: {
          default: () => import('@/views/Info.vue'),   
        },
      },
      {
        path: 'error-404',
        name: '404-page',
        components: { default: () => import('@/views/404-page.vue')}
      },
    ],    
  },
  
  // {
  //   path: '*',
  //   component: {
  //     render: (h) => h('router-view')                   
  //   },
  //   beforeEnter(to, from, next) {    
  //     const lang = to.params.lang
  //     if (!langsArray.includes(lang)) {
  //       next(CONFIG.languages[store.state.language_id].code + '/')
  //     } else {
  //       next()
  //     }                            
  //   }
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0, behavior:'smooth' }
  }
})

router.beforeEach((to, from, next) => {
    const languageUrlSegment = to.path.split('/');      
    languageUrlSegment.shift();    
    // console.log('languageUrlSegment',languageUrlSegment)
    let language = languageUrlSegment[0];              
    const configLangsArray = Object.values(CONFIG.languages)                  
    
    if (configLangsArray.findIndex(item => item.code === language) > -1){
      //valid language url
      if (languageUrlSegment.length === 1){   
        // home page url - solveing the /hu and /en problem     
        next({
          name: 'home',
          params:{
            lang: language
          }
        })   
      } else {
        to.params.lang = language
        store.state.language_id = configLangsArray.find(item => item.code === language).id         
        next()   
      }
      
    } else {
      //not exists the language
      language = CONFIG.languages[store.state.language_id].code      
      next({
        name: 'home',
        params:{
          lang: language
        }
      })      
    }       
    // next()
  })

export default router
