<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <transition
        name="slide-up"
        mode="out-in" 
      >
      <Preloader v-if="preloaderIs"/>  
    </transition>
    <Header :menuItems="menuItems"/>
    <transition
        name="slide-up"
        mode="out-in" 
      >
      <router-view class="flex-fill"/>
    </transition>
    <Footer :menuItems="menuItems" />
   
    <CookiePolicy  />
    <GradientBackground  />
  </div>
</template>

<script>
//import axios from "axios"
import apiH from '@/mixins/apiHandlerMI.js'
import Preloader from '@/components/Preloader'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import GradientBackground from '@/components/decors/GradientBackground'
import CookiePolicy from '@/components/CookiePolicy.vue'


export default {
  mixins: [apiH],
  components: {
    Preloader,
    Header,
    Footer,
    CookiePolicy,
    GradientBackground
  },
  data() {
        return {
          language: null,
          menuItems: [],
          preloaderIs: true
        }
  },
  methods: {
    getMenu(){
      var _this = this
        _this.get('helper/menu/1', {
            params: {
                // language_id: this.$store.state.language_id,                      
                relations: 1,
                where: [['where','status', '1']],   
                order_by:[
                    ['sort_order','asc']                
                ],                    
            }
        },
        function(resp){       
            _this.menuItems = resp.data.menu
            _this.$store.commit("updateMenu", resp.data.menu)
        }, function(err){
            console.log(err)
        }
      )
    },
    hidePreloader(){
      setTimeout(() => {
        this.preloaderIs = false
      }, 1500)
    }
  },
  watch:{
    $route(){
      window.dataLayer.push({
          'event': 'eventNavigation',
          'category': '',
          'action': '',
          'label': ''
      });

      //clear meta tags
      this.deleteMetaTags();     
    }
  },
  created () {
      this.getMenu()
      /* this.getLang() */ 
  },
  mounted () {
    this.hidePreloader()
  }
}
</script>