<template>
       <div id="cookie-policy" class="cookie-policy" v-if="needCookieSettings">            
          <div class="cookie-policy__inner">
                                                                                 
                    <div class="text">
                        <div>
                            Tájékoztatjuk, hogy a honlap cookie-kat (sütiket) használ, melyek lehetővé teszik a honlap teljes körű működését, valamint segítik a honlap használatát és hozzájárulnak a személyre szabott tartalmak és hirdetések elhelyezéshez, továbbá a közösségi média funkciók biztosításához.
                        </div>
                        <img src="@/assets/img/decors/intro-horizontal.svg" class="design-line" />
                        <div>
                            Az „Igen” gombra kattintással valamennyi cél eléréséhez szükséges süti alkalmazása jóváhagyásra kerül, ugyanakkor a „Beállítások” gombra kattintva lehetőség nyílik a honlapon használt sütik alkalmazásáról dönteni és kiválasztani, hogy milyen célú sütiket kíván engedélyezni. 
                            <div class="link-wrap">
                                <a href="/documents/cookie_policy.pdf" target="_blank">Cookie (süti) tájékoztató. </a>
                            </div>                            
                        </div>
                    </div>
                    <!-- <button type="button" class="btn--close" onclick="closeCookiePolicy()"></button> -->
                    <div class="cookies-setting" v-if="changeCookies">
                        <div class="custom-control custom-checkbox">
                            <input true-value="1" false-value="0" v-model="cookies.required" type="checkbox" class="custom-control-input" id="required_chbx"  disabled />
                            <label class="custom-control-label" for="required_chbx">Funkcionális sütik</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                            <input true-value="1" false-value="0" v-model="cookies.statistic" type="checkbox" class="custom-control-input" id="statistics_chbx" />
                            <label class="custom-control-label" for="statistics_chbx">Analitikai sütik</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                            <input true-value="1" false-value="0" v-model="cookies.marketing" type="checkbox" class="custom-control-input" id="marketing_chbx" />
                            <label class="custom-control-label" for="marketing_chbx">Marketing sütik</label>
                        </div>
                    </div>
                    <div class="btn-wrap">                                                                                            
                        <button type="button" v-if="!changeCookies" class="btn--white btn" @click="showCookieSettings()">Beállítások</button>
                        <button type="button" v-if="changeCookies" class="btn--white btn" @click="saveCookieSettings()">Beállítások mentése és kilépés</button>
                        <button type="button" class="btn--brown btn" @click="acceptCookies()">Igen!</button>
                    </div>                                                  
                           
            </div>            
        </div>
</template>

<script>
export default {
    data(){
        return{
            cookiePrefix: 'nt-',
            needCookieSettings: true,
            changeCookies: false,
            baseUrl: process.env.VUE_APP_BASE_URL,
            cookies:{
                required: '1',
                marketing: '0',
                statistic: '0'
            },
            expiredDate: 60 * 60 * 24 * 30
        }
    },   
    mounted(){
      var _this = this

      if ( parseInt(this.$cookies.get( '' +_this.cookiePrefix + "required_cookie")) === 1 ){          
          //the cookie settings was filled
        _this.needCookieSettings = false
        
        // this.setGTMScriptTag();    
      } 

      document.getElementById('cookieSettings').onclick = function () {
          _this.needCookieSettings = true
          _this.changeCookies = true
          _this.changeAllCookies()
      }
    }, 
    methods:{
        acceptCookies(){
            //accept all
            var _this = this
            // required_cookie, marketing_cookie, statistic_cookie            
            this.$cookies.set('' +_this.cookiePrefix + 'required_cookie', 1, this.expiredDate);            
            this.$cookies.set('' +_this.cookiePrefix + 'marketing_cookie', 1, this.expiredDate);                        
            this.$cookies.set('' +_this.cookiePrefix + 'statistic_cookie', 1, this.expiredDate);
                       

            this.needCookieSettings = false
                    
        },
        showCookieSettings(){
            this.changeCookies = true
        },
        saveCookieSettings(){
            var _this = this
            this.$cookies.set('' +_this.cookiePrefix + 'required_cookie', 1, this.expiredDate);
            
            if ( parseInt(this.cookies.marketing) === 1 ){
                this.$cookies.set('' +_this.cookiePrefix + 'marketing_cookie', 1, this.expiredDate);
            } else {
                this.$cookies.remove('' +_this.cookiePrefix + 'marketing_cookie');                
            }

            if ( parseInt(this.cookies.statistic) === 1 ){
                this.$cookies.set('' +_this.cookiePrefix + 'statistic_cookie', 1, this.expiredDate);
            } else {
                this.$cookies.remove('' +_this.cookiePrefix + 'statistic_cookie');                
            } 

            this.needCookieSettings = false
                  
        },
        changeAllCookies(){
            let _this = this

            _this.cookies.marketing = this.$cookies.get('' +_this.cookiePrefix + 'marketing_cookie')
            _this.cookies.statistic = this.$cookies.get('' +_this.cookiePrefix + 'statistic_cookie')
            
        }
             
    }
}
</script>
