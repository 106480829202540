<template>
    <div class="contact">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12" md="6" class="contact__box">
                    <div class="contact__box--info">
                        <h2 class="contact__box--info--title">{{tr("contact")}}</h2>
                        <h4 class="contact__box--info--subtitle">
                        {{tr("nt-name")}}
                        </h4>
                        <div
                            class="contact__box--info--txt" 
                            v-for="(data, idx) in contactData"
                            :key="'contact' + idx"
                            >
                            <a v-if="idx===1" href="tel:+3676431628">{{ data.value }}</a>
                            <a v-else-if="idx===3" href="mailto: info@ntkft.hu">{{ data.value }}</a>
                            <p v-else>{{ data.value }}</p>
                        </div>
                    </div>
                    
                </b-col>
                <b-col cols="12" md="6" class="contact__box">
                    <div class="contact__box--map">
                        <!-- <GmapMap
                            :center="{lat: 46.68943, lng:19.85479}"
                            :zoom="13"                                                
                            style="width: 100%; height: 100%">
                        </GmapMap> -->

                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10947.38901923106!2d19.8542354!3d46.6890156!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3209a4f0708974b2!2sNt%20Kft.!5e0!3m2!1shu!2shu!4v1639476307527!5m2!1shu!2shu"
                            width="100%" 
                            height="100%" 
                            style="border:0;" 
                            allowfullscreen="" 
                            loading="lazy">
                        </iframe>
                    </div>
                </b-col>                            
            </b-row>
        </b-container>
    </div>

</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'

//import Vue from 'vue'
//import { gmapApi } from 'vue2-google-maps'
//import * as VueGoogleMaps from 'vue2-google-maps'

/* Vue.use(VueGoogleMaps,{
    load: {
        key: 'AIzaSyAOc5kpt9op4-abzkaeJDJyfjgkwkbmEhg',
        libraries: 'places', 
}}) */


export default {
  name: 'Contact',
  mixins: [apiH],
  data() {
    return {
      contactData: []
      }
  },
  created () {
      var _this = this
      _this.get('setting', {
      params: {                        
          relations: 1,
          where: [['where','key', 'address']],                    
      }
    },
          function(resp){
              _this.contactData = resp.data.setting
          }, function(err){
              console.log(err)
          }
      )
    },
    /* computed: {
            google: gmapApi
        }, */
}
</script>