export const apiUrl = process.env.VUE_APP_API_URL;

export const defaultLanguageId = 1;
export const needLanguage =  true;
export const languages = {
    1: {
        id: 1,
        code: "hu",
        name: "HUN",
        full_name: "Magyar",
        target_link : ""
    },
    2: {
        id: 2,
        code: "en",
        name: "ENG",
        full_name: "English",
        target_link : ""
    }
}
export const defaultPage = 'Home';
export const project = {
        title: 'Nt Kft.'
}
