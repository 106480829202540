<template>
    <div class="home-career">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12" md="6" order="1" order-md="1" class="home-career__box">
                    <div class="home-career__image">
                    </div>
                </b-col>        
                <b-col cols="12" md="6" order="2" order-md="2" class="home-career__box">
                    <div class="home-career__content">
                        <h1>{{tr("carrier-title")}}</h1>
                        <p>{{tr("carrier-desc")}}</p>
                        <div class="home-career__content--button">
                            <SimpleBtn 
                                type="dark"
                                :link="link"
                                :dlCategory="'careers'"
                                :dlAction="'open_positions_click'"
                                :dlLabel="''"
                            >
                                    {{tr("carrier-btn")}}
                            </SimpleBtn>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>        
</template>

<script>
import SimpleBtn from '@/components/decors/SimpleBtn.vue'
export default {
    name: 'HomeCareer',
    components: {
        SimpleBtn
    },
    data(){
        return {
            // link: this.$store.state.menu[5].target_link
        }
    },
    /* methods: {
        goToJob(){
            let link = "karrier_" + this.currentLangCode
            console.log(link)
            return this.$router.push({name: link})
        }
    }, */
    computed:{
        link(){
        let link
        let menuItems = this.$store.state.menu
        for(let i=0; i<menuItems.length; i++) {
            if(menuItems[i].target_link == 'karrier' || menuItems[i].target_link == 'career') {
            link = menuItems[i].target_link
            }
        }
        return link
        }
    }
}
</script>