<template>
    <div 
        class="header"
        :class="{ 'hidden-navbar': !showNavbar }"
    >
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12">
                    <b-navbar
                        class="header__navbar" 
                        toggleable="lg">
                        
                        <b-navbar-brand>
                            <router-link 
                                @click.native="setNavItemGTM('Logo')"
                                class="header__navbar--logo" :to="{name: 'home', params:{lang: languages[currentLangId].code}}">
                                 <img class="header__navbar" src="@/assets/img/main-logo.jpg" alt="NT Kft."/>
                               <!-- <img class="header__navbar--logo--lg" src="@/assets/img/brand-logo-no-text.svg" alt="NT Kft."/> 
                                <img class="header__navbar--logo--sm" src="@/assets/img/brand-logo-no-text.svg" alt="NT Kft."/> -->
                            </router-link>
                        </b-navbar-brand>
                        <b-navbar-toggle @click="hamburgerChecker" target="nav-collapse">
                            <div
                                class="header__navbar--hamburger"
                                :class="{ 'header__navbar--hamburger--close' : isOpen }">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </b-navbar-toggle>
                        
                        <b-collapse id="nav-collapse" is-nav>
                            <b-navbar-nav>
                                
                                <div @click="hamburgerChecker"
                                    v-for="(item, idx) in menuItems" 
                                    :key="'route' + idx">
                                    <li class="nav-item nav-link"
                                    v-if="item.parent_id==0">
                                        <router-link
                                        :to="{path: baseUrl + languages[currentLangId].code + '/' + item.target_link}"
                                        @click.native="setNavItemGTM(item.name)"
                                        >
                                            {{ item.name }}
                                        </router-link>
                                        <!-- <a
                                        :href="baseUrl + languages[currentLangId].code + '/' + item.target_link"
                                        @click="setNavItemGTM(item.name)"
                                        >
                                            {{ item.name }}
                                        </a> -->
                                    </li>
                                </div>
                                <div class="header__navbar--mobile-lang">
                                    <div 
                                        v-for="(lang, idx) in languages" :key="'lang' + idx"
                                    >    
                                        <p 
                                            v-if="lang.id !== languages[currentLangId].id"
                                            class="header__navbar--mobile-lang--items"
                                            @click="setLang(lang.id, lang.code)">
                                            {{ lang.full_name }}
                                        </p>
                                                                            
                                    </div>
                                </div>
                                
                                
                            </b-navbar-nav>
                        </b-collapse>
                        <div class="header__spacer"></div>
                        <LangSwitcher :languages="languages"/>
                    </b-navbar>
                </b-col>                            
            </b-row>
        </b-container>
    </div>
    
</template>

<script>
import * as CONFIG from '@/setup/init.js'
import LangSwitcher from '@/components/LangSwitcher.vue'
export default {
    name: 'Header',
    components: {
        LangSwitcher
    },
    props: [
        'menuItems'
    ],
    data() {
        return {
            isOpen: false,
            showNavbar: true,
            lastScrollPosition: 0,
            scrollValue: 0,
            languages: CONFIG.languages            
        }
    },
    methods: {
        hamburgerChecker () {
            if(window.innerWidth < 768) {
                console.log("most kisebb")
                this.isOpen = !this.isOpen
                this.isOpen 
                ? document.querySelector('body').style.overflowY = "hidden" 
                : document.querySelector('body').style.overflowY = "auto"
            }
        },
        onScroll () {
            if (window.pageYOffset < 0) {
                return
            }
            if (Math.abs(window.pageYOffset - this.lastScrollPosition) < 80) {
                return
            }
            this.showNavbar = window.pageYOffset < this.lastScrollPosition
            this.lastScrollPosition = window.pageYOffset
        },
        setNavItemGTM(item){
            
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'header',
                'action': 'click',
                'label': item
            })
        }
    },
    mounted () {
        this.lastScrollPosition = window.pageYOffset
        window.addEventListener('scroll', this.onScroll)
        const viewportMeta = document.createElement('meta')
        document.head.appendChild(viewportMeta)
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.onScroll)
    }
}

</script>