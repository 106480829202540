<template>
    <b-container fluid="xl">
      <b-row>
        <b-col cols="12">
          <div v-if="dataSet===null || $store.state.menu.length === 0" class="empty-div"></div>
          <div v-else>
            <Title
              :title="sliderData.name" 
              :description="sliderData.short_description"
              :button="$store.state.menu[1].target_link"
              :carousel="sliderData.slider_image"
            />
          </div>
          <SimpleLine/>
          <FooterBanner/>
          <SimpleLine/>
          <HomeItem
            v-for="(item, idx) in dataSet" 
            :key="'item' + idx"
            :item="item"
            />
          <HomeCareer 
            v-if="isJob"/>
          <SimpleLine 
            v-if="isJob"/>
          <HomeBanner />

          <SimpleLine/>
          <Contact />

          <!-- Antwerp declaration -->
          <div class="row">
            <div class="col-12 text-center mb-4 pb-4 mt-4 pt-4 pt-md-0 mt-md-0">
              <a href="https://antwerp-declaration.eu/" target="_blank">
                <img src="/images/antwerp-horizontal-blue.PNG" alt="Antwerp Declaration" class="img-fluid w-full d-none d-md-block"/>
              </a>
              <a href="https://antwerp-declaration.eu/" target="_blank">
                <img src="/images/antwerp-square-blue.JPG" alt="Antwerp Declaration" class="img-fluid d-md-none w-full"/>
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import Title from '@/components/Title.vue'
import HomeItem from '@/components/HomeItem.vue'
import HomeCareer from '@/components/HomeCareer.vue'
import SimpleLine from '@/components/decors/SimpleLine.vue'
import HomeBanner from '@/components/HomeBanner'
import FooterBanner from '@/components/FooterBanner'
import Contact from '@/components/Contact'
import apiH from '@/mixins/apiHandlerMI.js'

export default {
  components: {
    HomeItem,
    FooterBanner,
    HomeCareer,
    SimpleLine,
    HomeBanner,
    Title,
    Contact
  },
  mixins: [apiH],
  data() {
    return {
      dataSet: null,
      sliderData: [],
      isJob: false
      }
  },
  created () {
  var _this = this
  _this.get('home_information', {
      params: {                        
          relations: 1,                    
      }
    },
    function(resp){       
        _this.dataSet = resp.data.home_information
    }, function(err){
        console.log(err)
        _this.jobs = []
    }
  )

  _this.get('job/1',
    {
      params: {                        
          relations: 1,
          where: [['where','status', '1']]                    
      }
    },
    function(resp){       
        resp.data.job.length > 0 ? _this.isJob = true : _this.isJob = false
    }, function(err){
        console.log(err)
        _this.isJob = false
    }
  )

  _this.get('slider/1', {
    params: {                        
            relations: 1,
            where: [['where','status', '1']]                    
                }
    },
    function(resp){       
        _this.sliderData = resp.data.slider[0]
    }, function(err){
        console.log(err)
        _this.sliderData = []
    }
  )
  },
  mounted(){
    this.setMetaTags({
        title: null, 
        desc: null, 
        keywords: 'NT KFt, NT Élelmiszertermelő és Kereskedelmi Kft, napraforgó étolaj', 
        ogType: 'website', 
        ogTitle: null,
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc:null
    })
  }
}
</script>

