import Vue from 'vue'
import Vuex from 'vuex'
import * as CONFIG from '@/setup/init.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiCalls: {},
    userType: 'visitor',
    language_id: CONFIG.defaultLanguageId,
    menu: []
  },
  mutations: {
    updateLang(state, value) {
      state.language_id = value
    },
    updateMenu(state, value) {
      state.menu = value
    }
  },
  actions: {
  },
  modules: {
  }
})
