<template>
  <div class="footer">
    <b-container fluid="xl">
      <b-row class="footer__wrapper">
        <div class="footer__logo">
          <img class="footer__logo--logo" src="@/assets/img/brand-logo-no-text.svg" alt="NT Kft."/>          
        </div>
        <div class="footer__menu">
          <div
            class="footer__menu--item"  
            v-for="(item, idx) in menuItems"
            :key="'route' + idx">
            <router-link
              v-if="item.target_link !== '/' && item.parent_id==0"
              class="footer__menu--link" 
              :to="{path: item.target_link}"
              @click.native="setNavItemGTM(item.name)"
              >
            {{ item.name }}
          </router-link>
          </div>
        </div>                            
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="footer__bottom-line"></div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid="xl" class="footer__bottom">
      <b-row align-v="center">
        <b-col cols="12" lg="5" class="footer__bottom--left">
          <div class="footer__bottom--txt">
            <span>Copyright 2017-</span>  
            <span>{{currentYear}} </span>
            <span>{{tr("nt-name")}}</span>
          </div>
        </b-col>
        <b-col cols="12" lg="7" class="footer__bottom--right">
          <a href="/documents/cookie_policy.pdf" class="footer__bottom--txt" target="_blank">{{tr("cookie-policy")}}</a>
          <button id="cookieSettings" class="footer__bottom--txt" target="_blank">{{tr("cookie-change")}}</button>
          <a :href="'/documents/copyright-' + currentLangCode + '.pdf'" class="footer__bottom--txt" target="_blank">{{tr("cookie-info")}}</a>     
          <a :href="'/documents/Information-' + currentLangCode + '.pdf'" class="footer__bottom--txt" target="_blank">{{tr("footer-whistleblowing")}}</a>     
        </b-col>                            
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  props: ["menuItems"],
  name: 'Footer',
  computed: {
    currentYear: function () {
      return new Date().getFullYear()
    }
  },
  methods:{
       setNavItemGTM(item){
            
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'footer',
                'action': 'click',
                'label': item
            })
        },
  }
}
</script>