<template>
    <div>
        <div class="home">
            <b-container fluid="xl">
                <b-row>
                    <b-col 
                        cols="12" 
                        md="6" 
                        order="2" 
                        :order-md="item.information_options ==='left' ? 1 : 2"
                        class="home__box">
                        <div class="home__content">
                            <h2>{{item.information.name}}</h2>
                            <p>{{item.lead_description}}</p>
                            <div class="home__content--button">
                                <SimpleBtn
                                    type="dark"
                                    :link="item.information.slug"
                                    :dlCategory="'details'"
                                    :dlAction="no_accents(item.information.name) + '_click'"
                                    :dlLabel="item.information.name"
                                >
                                        {{tr("details")}} 
                                </SimpleBtn>  
                            </div>
                        </div>
                    </b-col>        
                    <b-col 
                        cols="12" 
                        md="6" 
                        order="1" 
                        :order-md="item.information_options ==='left' ? 2 : 1" 
                        class="home__box">
                        <div class="home__image">
                            <div :class="item.information_options ==='left' ? 'home__image--left' : 'home__image--right'">
                                <div 
                                    class="home__image--image"
                                    :style="{'background-image': 'url(' + imgUrlBcg(item.image_background) + ')' }">
                                    <div 
                                        v-if="item.information_options ==='left'" 
                                        class="home__image--image--over home__image--image--over--left"
                                        :style="{'background': 'url(' + imgUrl(item.image) + ')' }">
                                    </div>
                                    <div 
                                        v-else 
                                        class="home__image--image--over home__image--image--over--right"
                                        :style="{'background-image': 'url(' + imgUrl(item.image) + ')' }">
                                    </div>
                                </div>                
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <SimpleLine/>
    </div>        
</template>

<script>

import SimpleBtn from '@/components/decors/SimpleBtn.vue'
import SimpleLine from '@/components/decors/SimpleLine.vue'
export default {
    name: 'HomeItem',
    components: {
        SimpleBtn,
        SimpleLine
    },
    data(){
        return{
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME
        }
    },
    props: ['item'],
    methods: {
        imgUrl: function (data) {
            return this.src_url + data.cache.w255h255 
        },
        imgUrlBcg: function (data) {
            return this.src_url + data.cache.w540h540
        }
    }
}

</script>