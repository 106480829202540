<template>
    <div>
        <div 
            v-if="link!=='null'">
            <router-link
                class="simple-button" 
                :to="link"
                @click.native="setDataLayer(dlCategory,dlAction,dlLabel)"
            >
                <div :class="'button button__' + type">
                    <p :class="'button__' + type + '--arrow'">
                        <slot></slot>
                    </p>
                </div>
            </router-link>
        </div>
        <div 
            v-else
            :class="'button button__' + type"
            @click="setDataLayer(dlCategory,dlAction,dlLabel)"
        >
            <p :class="'button__' + type + '--arrow'">
                <slot></slot>
            </p>
        </div>
    </div>    
</template>

<script>
export default {
    props: ['type', 'link', 'dlCategory', 'dlAction', 'dlLabel'],
    methods:{
        setDataLayer(category, action, label){
            this.GTtrackGA4({
                'event' : 'event',
                'category': category,
                'action': action,
                'label': label
            })

        }
    }
}
</script>